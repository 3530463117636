<template>
  <v-dialog
    v-model="isOpen"
    max-width="600"
    @input="handleClose"
  >
    <v-card>
      <v-card-title class="headline pb-10">
        <div class="columns">
          <div class="column" v-if="isCreate">
            <h2 class="title is-3">Создать форму</h2>
          </div>
          <div class="column" v-else>
            <h2 class="title is-3">Изменить форму: {{ currentFormName }}</h2>
          </div>
        </div>
      </v-card-title>
      <v-card-text>
        <v-layout row>
          <v-layout column>
            <b-field label="Название">
              <b-input v-model="currentForm.name"></b-input>
            </b-field>

            <div v-if="currentForm.user_access">
              <v-divider/>
              <label class="label">Доступы</label>

              <b-field class="mt-4" label="Все пользователи">
                <b-switch
                  @input="onAllUsersChange"
                  v-model="currentForm.user_access.all">
                  {{ currentForm.user_access.all ? 'Да' : 'Нет' }}
                </b-switch>
              </b-field>

              <div v-if="!currentForm.user_access.all">
                <p class="error--text">Когда выбираете человека в группах пользователей, доступ будет у него и ВСЕЙ ЕГО КОМАНДЫ</p>

                <v-select
                  outlined
                  v-model="currentForm.user_access.subordinates_of"
                  label="Группы пользователей"
                  :items="teamleads"
                  item-value="id"
                  item-text="name"
                  multiple
                  class="mt-7"
                ></v-select>

                <v-select
                  class="mb-3"
                  outlined
                  v-model="currentForm.user_access.users"
                  label="Пользователи"
                  :items="profiles"
                  item-value="id"
                  item-text="name"
                  multiple
                ></v-select>
              </div>
              <v-divider/>
            </div>

            <b-field label="HTML-код">
              <b-input v-model="currentForm.html_code" type="textarea"></b-input>
            </b-field>

            <b-field label="Превью" v-if="!isCreate">
              <b-input v-model="currentForm.preview_path" disabled></b-input>
            </b-field>

            <p class="error--text" v-if="currentForm.preview_path && isCreate">
              Файл уже загружен. Не загружайте новый, если не хотите ничего менять.
            </p>

            <b-field>
                <b-upload
                  v-model="currentForm.files"
                  :multiple="false"
                  drag-drop
                  class="full-width-upload"
                  accept="image/*"
                  @input="handleFileUpload"
                >
                  <section class="section">
                    <div class="content has-text-centered">
                      <p>
                        <b-icon
                          icon="upload"
                          size="is-large"
                        />
                      </p>
                      <p v-if="isCreate">Перетащите ваше превью сюда или кликните для загрузки</p>
                      <p v-else>Для изменения превью перетащите его сюда</p>
                    </div>
                  </section>
                </b-upload>
            </b-field>

            <div class="tags">
              <span v-for="(file, index) in currentForm.files"
                :key="index"
                class="tag is-primary">
                {{ file.name }}
                <button class="delete is-small"
                        type="button"
                        @click="deleteDropFile(index)">
                </button>
              </span>
            </div>
          </v-layout>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close()">Закрыть</v-btn>
        <v-btn text color="primary" v-if="isCreate" @click="createForm()">Добавить</v-btn>
        <v-btn text color="primary" v-else @click="updateForm()">Обновить</v-btn>
      </v-card-actions>
      <overlay-loader :show="isLoading"></overlay-loader>
    </v-card>
  </v-dialog>
</template>

<script>
import OverlayLoader from '@/app/shared/components/app-loader/Loader';
import { toFormData } from '@/app/shared/services/helpers';

export default {
  name: 'FormDetailsModal',
  components: {
    OverlayLoader,
  },
  data() {
    return {
      currentFormName: '',
    };
  },
  created() {
    this.getProfiles();
    this.getTeamLeads();
  },
  watch: {
    isOpen(newVal) {
      if (newVal) {
        this.currentFormName = this.currentForm.name;
      }
    },
  },
  computed: {
    profiles: {
      get() {
        return this.$store.getters.GET_PROFILES;
      },
      set(value) {
        this.$store.commit("SET_PROFILES", value);
      },
    },
    teamleads() {
      return this.$store.getters.GET_TEAMLEAD_DATA;
    },
    isCreate() {
      return this.currentForm && this.currentForm.id == null;
    },
    isLoading: {
      get() {
        return this.$store.getters.GET_FORM_DETAILS_MODAL_LOADING;
      },
      set(value) {
        this.$store.commit("SET_FORM_DETAILS_MODAL_LOADING", value);
      },
    },
    isOpen: {
      get() {
        return this.$store.getters.GET_FORM_DETAILS_MODAL_OPENED;
      },
      set(value) {
        this.$store.commit('SET_FORM_DETAILS_MODAL_OPENED', value);
      },
    },
    currentForm: {
      get() {
        return this.$store.getters.GET_CURRENT_FORM;
      },
      set(value) {
        this.$store.commit("SET_CURRENT_FORM", value);
      },
    },
  },
  methods: {
    onAllUsersChange() {
      if (this.currentForm.user_access.all) {
        this.currentForm.user_access.users = [];
        this.currentForm.user_access.subordinates_of = [];
      }
    },
    handleFileUpload(file) {
      this.currentForm.files = [file];
    },
    deleteDropFile(index) {
      this.currentForm.files.splice(index, 1);
    },
    handleClose(isOpen) {
      if (!isOpen) {
        this.close();
      }
    },
    close() {
      this.currentForm = {};
      this.isOpen = false;
      this.isLoading = false;
    },
    createForm() {
      this.$store.dispatch('CREATE_FORM', this.customToFormData(this.currentForm))
        .then(() => {
          this.$toast.success("Форма создана!");
        })
        .catch(() => {
          this.$toast.error("Ошибка при создании формы");
        }).finally(() => {
        this.close();
        });
    },
    updateForm() {
      let data = {
        id: this.currentForm.id,
        form: this.customToFormData(this.currentForm)
      }
      this.$store.dispatch('UPDATE_FORM', data)
        .then(() => {
          this.$toast.success("Форма успешно изменена!");
          this.close();
        })
        .catch(() => {
          this.$toast.error("Ошибка при изменении формы");
        });
    },
    customToFormData(params) {
      const formData = new FormData();

      Object.keys(params).forEach(key => {
        if (key !== 'files') {
          const value = params[key];

          // Если значение — объект (но не File и не null), сериализуем его
          if (typeof value === 'object' && value !== null && !(value instanceof File)) {
            formData.append(key, JSON.stringify(value));
          } else {
            formData.append(key, value);
          }
        }
      });

      if (params.files && Array.isArray(params.files)) {
        params.files.forEach(file => {
          if (file instanceof File) {
            formData.append('files', file);
          }
        });
      }

      return formData;
    },
    getProfiles() {
      this.loading = true;
      const params = {
        filters: {},
        type: 'SET_PROFILES',
      };
      this.$store
        .dispatch("GET_PROFILES", params)
        .then((data) => {
        })
        .catch((error) => {
          this.$toast.error("Неизвестная ошибка!");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getTeamLeads() {
      this.loading = true;
      const params = {
        filters: { role: 'teamlead', status: 'true' },
        type: 'SET_TEAMLEADS',
      };
      this.$store
        .dispatch("GET_PROFILES", params)
        .then((data) => {})
        .catch((error) => {
          this.$toast.error("Неизвестная ошибка!");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.full-width-upload {
  width: 100%;
  display: block;
}

.full-width-upload .section {
  width: 100%;
}

.full-width-upload .content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
</style>
